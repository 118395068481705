import { ColorString, neutral } from "../../colors";
import { FontWeights } from "../../faro-theme";
import { FaroChip, FaroChipProps } from "../chip";

export type FaroChipTagProps = Pick<
  FaroChipProps,
  "label" | "size" | "color" | "onClick" | "onDelete" | "className"
> & {
  dark?: boolean;
};

type Colors = {
  backgroundColor: ColorString;
  color: ColorString;
  borderColor: ColorString;
};

const DARK_COLORS: Colors = {
  backgroundColor: neutral[900],
  color: neutral[100],
  borderColor: neutral[0],
};

const LIGHT_COLORS: Colors = {
  backgroundColor: neutral[100],
  color: neutral[800],
  borderColor: neutral[999],
};

/**
 * @returns A chip representing tags for the elements in the project
 */
export function FaroChipTag({
  label,
  size,
  color,
  dark = false,
  className,
  onClick,
  onDelete,
}: FaroChipTagProps): JSX.Element {
  const colors = dark ? DARK_COLORS : LIGHT_COLORS;

  return (
    <FaroChip
      label={label}
      backgroundColor={colors.backgroundColor}
      borderColor={`${colors.borderColor}33`}
      color={colors.color}
      size={size}
      variant="outlined"
      textTransform="none"
      icon={
        color ? (
          <div
            style={{
              width: size === "small" ? "10px" : "14px",
              height: size === "small" ? "10px" : "14px",
              backgroundColor: color,
              borderRadius: size === "small" ? "5px" : "7px",
            }}
          />
        ) : undefined
      }
      sx={{
        letterSpacing: 0,
        "&.MuiChip-sizeSmall": {
          fontSize: "12px",
          lineHeight: "18px",
          height: "22px",
          verticalAlign: "center",
          fontWeight: FontWeights.Bold,
        },
        "&.MuiChip-sizeLarge": {
          fontSize: "14px",
          lineHeight: "20px",
          height: "30px",
          verticalAlign: "center",
          fontWeight: FontWeights.SemiBold,
        },
        "& .MuiChip-label": {
          pl: color ? "6px" : 0,
          pr: onDelete ? "8px" : 0,
        },
        "& .MuiChip-icon": {
          marginLeft: 0,
          marginRight: 0,
        },
        "& .MuiChip-deleteIcon": {
          color: colors.color,
        },
        ...(onClick && {
          "&.MuiButtonBase-root": {
            ":hover": {
              backgroundColor: `color-mix(in srgb, ${colors.backgroundColor}, ${neutral[500]} 15%)`,
            },
            ":active": {
              backgroundColor: `${neutral[500]}40`,
              boxShadow: 0,
            },
            ":focus": {
              backgroundColor: `${neutral[500]}40`,
              border: `2px solid ${colors.borderColor}`,
            },
          },
        }),
        px: "8px",
        py: size === "small" ? "2px" : "5px",
      }}
      onClick={onClick}
      onDelete={onDelete}
      className={className}
    />
  );
}
