uniform float size;
uniform float scale;

uniform float uOffset;
uniform float uSlope;
uniform vec2 uViewportSize;
uniform float uOrthoFactor;
uniform float uOctreeSpacing;
uniform bool uIsOrthographic;
uniform vec3 uBoxMin;
uniform vec3 uBoxMax;
uniform float uLevel;

uniform float uMinSize;
uniform float uMaxSize;

uniform float uDpr;

uniform sampler2D uTreeStructureTex;
uniform sampler2D uBoxTex; 

uniform bool uDebug;

#include <common>
#include <color_pars_vertex>
#include <fog_pars_vertex>
#include <morphtarget_pars_vertex>
#include <logdepthbuf_pars_vertex>
#include <clipping_planes_pars_vertex>

// Linearly interpolate a value on a line described by two points (x0, y0)
// and (x1, y1)
float interpolate(float x, float x0, float y0, float x1, float y1) {
	return y0 + ((x - x0) * (y1 - y0)) / (x1 - x0);
}

// A base C0 function describing a window
float base(float x) {
	if (x <= -0.75) {
		return 0.0;
	} else if (x <= -0.25) {
		return interpolate(x, -0.75, 0.0, -0.25, 1.0);
	} else if (x <= 0.25) {
		return 1.0;
	} else if (x <= 0.75) {
		return interpolate(x, 0.25, 1.0, 0.75, 0.0);
	}
	return 0.0;
}

// Clamp the value between 0 and 1
float convert(float t) {
	return clamp(t, 0.0, 1.0);
}

// Convert a scalar value to a RGB color (following a JET color map, i.e. max value is 
// red, minimum value is blue). 
vec3 scalarToJetColor(float value, float minValue, float range) {
	// Get clipped value in [-1,+1]
	float v = clamp((value - minValue) / range * 2.0 - 1.0, -1., 1.);

	// Compute the three color components
	float r = convert(base(v - 0.5));
	float g = convert(base(v));
	float b = convert(base(v + 0.5));

	return vec3(r, g, b);
}

// Check if a bounding box (described by the minimum and maximum point)
// contains the query point
bool containsPoint( vec3 min, vec3 max, vec3 point ) {
	return (point.x >= min.x && point.x <= max.x &&
		point.y >= min.y && point.y <= max.y &&
		point.z >= min.z && point.z <= max.z);
}

// Given a point, compute the actual tree depth, based 
// of the number of nodes in which this point falls
float getLOD(){
	int iOffset = int(uOffset);
	float depth = uLevel;
	vec3 normalizedPosition = (position - uBoxMin) / (uBoxMax - uBoxMin);
	float lodOffset = 0.0;
	for(float i = 0.0; i <= 30.0; i++){
		vec4 value = texture2D(uTreeStructureTex, vec2((float(iOffset) + 0.5) / 2048.0, 0.0));
		if (value.r > 0.0) {
			// It has children
			// there are more visible child nodes at this position
			int advanceG = int(round(value.g * 255.0)) * 256;
			int advanceB = int(round(value.b * 255.0));
			int numChildren = int(round(value.r * 255.0));
			int childOffset = advanceG + advanceB;
			
			for (int j = 0; j < 8; j++) {
				if ( (j + 1) > numChildren ) break;
				int childIdx = j + childOffset + iOffset;
				vec4 boxInfo = texture2D(uBoxTex, vec2((float(childIdx) + 0.5) / 2048.0, 0.0));
				vec3 boxSize = vec3(boxInfo.a, boxInfo.a, boxInfo.a);

				vec3 childBoxMin = boxInfo.rgb - boxSize;
				vec3 childBoxMax = boxInfo.rgb + boxSize;
				if (containsPoint(childBoxMin, childBoxMax, normalizedPosition)) {
					iOffset = childIdx;
					++depth;
					break;
				}
			}
		} else {
			lodOffset = (255.0 * value.a) / 10.0 - 10.0;
			break;
		}
	}
	return depth + lodOffset;
}

float getPointSizeAttenuation(){
	float lod = getLOD();
#ifdef USE_COLOR
	if (uDebug) vColor = vec4(scalarToJetColor(lod, 0.0, 12.0), 1.0);
#endif
	return pow(2.0, lod);
}

float getPointSize(){
	float pointSize = 1.0;
	
	float scale = length(
		modelViewMatrix * vec4(0, 0, 0, 1) - 
		modelViewMatrix * vec4(uOctreeSpacing, 0, 0, 1)
	) / uOctreeSpacing;	

	float r = uOctreeSpacing * 1.7 * scale;
	float worldSpaceSize = 1.0 * size * r / getPointSizeAttenuation();
	if(uIsOrthographic) {
		pointSize = worldSpaceSize  * uOrthoFactor;
	} else {
		float projFactor = -0.5 * uViewportSize.y / (uSlope * (modelViewMatrix * vec4(position, 1.0)).z);
		pointSize = worldSpaceSize * projFactor;
	}

	pointSize = clamp(pointSize, uDpr * uMinSize, uDpr * uMaxSize);

	return pointSize;
}

void main() {
	#include <color_vertex>
	#include <morphcolor_vertex>
	#include <begin_vertex>
	#include <morphtarget_vertex>
	#include <project_vertex>

	gl_PointSize = uDpr * size;
	
	#ifdef USE_SIZEATTENUATION
		gl_PointSize = getPointSize();
	#endif
	
	#include <logdepthbuf_vertex>
	#include <clipping_planes_vertex>
	#include <worldpos_vertex>
	#include <fog_vertex>
}
